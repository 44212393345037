<script setup>
const storeConfig = useStoreConfig()
const { isRegionControlIsActive } = storeToRefs(storeConfig)

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true
})
</script>

<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <Head>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>
    <Body>
      <div class="bg-white">
        <div class="max-w-7xl mx-auto">
          <div class="lb-navigation !flex flex-wrap mb-5 mt-5">
            <div class="flex items-center justify-between sm:justify-start gap-5 w-full sm:w-auto">
              <Logo class="lb-nav-logo pl-2 sm:pl-0"/>
              <RegionSelector v-if="isRegionControlIsActive" :label="$t('widgets.region-management.delivery-region')" readonly transparent />
            </div>

            <div class="lg:flex hidden items-center space-x-4 divide-x divide-gray-300 col-span-5 justify-end gap-5">
              <LucideLockKeyhole class="h-6 w-6 text-green-500"/>
              <p class="whitespace-nowrap text-gray-400 font-medium text-base pl-4">{{ $t("basket.secure-payment") }}</p>
            </div>

            <div class="px-4 my-4 lg:hidden lg:w-full flex justify-end lg:col-span-5">
              <NuxtLinkLocale href="/cart" class="text-base text-primary flex items-center font-bold">
                <LucideArrowLeft class="w-4 h-4 mr-2"/>
                {{ $t("basket.back-to-cart") }}
              </NuxtLinkLocale>
            </div>
          </div>
          <section>
            <slot />
          </section>
        </div>
      </div>
    </Body>
  </Html>
</template>

